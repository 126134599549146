import {Fragment, useEffect, useRef, useState} from 'react'
import {useParams, useLocation} from 'react-router-dom'
import {DateRangePicker} from 'react-nice-dates'
import {KTCard, KTCardBody, KTSVG, toAbsoluteUrl} from '../../../../_metronic/helpers'
import SimService from '../../../../services/sim.service'
import {enGB} from 'date-fns/locale'
import 'react-nice-dates/build/style.css'
import XLSX from 'xlsx'

const SimOrders = () => {
  const providerId = useParams().providerId
  const userId = useParams().id
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  const imagePathSmall =
    searchParams.get('imagePathSmall') || toAbsoluteUrl('/media/logos/tygo_green.svg')

  // console.log('providerId:', providerId)
  // console.log('userId:', userId)

  const date = new Date()
  date.setDate(date.getDate() - 7)
  date.setHours(0, 0, 0, 0)
  const [startDate, setStartDate] = useState<any>(userId ? new Date(0) : date)
  const [endDate, setEndDate] = useState<any>(new Date(new Date().setHours(23, 59, 59, 999)))
  const [orderStatus, setOrderStatus] = useState<number>()
  const [error, setError] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pagesCount, setPagesCount] = useState(0)
  const [orders, setOrders] = useState<any[]>([])
  const [searchedOrders, setSearchedOrders] = useState<any[]>([])
  const [totalOrders, setTotalOrders] = useState<number>()
  const phoneNumberInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    fetchOrderData(
      startDate,
      endDate,
      currentPage,
      providerId as unknown as number,
      orderStatus,
      userId
    )
  }, [startDate, endDate, orderStatus])

  const fetchOrderData = async (
    start?: Date,
    end?: Date,
    pageNumber?: number,
    id?: number,
    status?: number,
    userId?: string
  ) => {
    try {
      // console.log('provider id:', id)
      const response = await SimService.GetOrderData({
        FromDate: start,
        ToDate: end,
        pageNumber,
        ProviderIds: id,
        PageSize: 20,
        OrderStatus: status,
        UserId: userId,
      })
      console.log('GetOrderData response:', response)
      setTotalOrders(response.data.totalRecords)
      setPagesCount(Math.ceil(response.data.totalRecords / 20))
      setCurrentPage(response.data.pageNumber)
      response.data.data.length > 0 ? setOrders(response.data.data[0].orders) : setOrders([])
      response.data.data.length > 0
        ? setSearchedOrders(response.data.data[0].orders)
        : setSearchedOrders([])
      setError(false)
    } catch (error) {
      setError(true)
      console.log(error)
    }
  }

  const fetchOrdersByPhoneNumber = async (phoneNumber: string) => {
    try {
      const numbers = [{phoneNumber, countryCode: '966'}]
      const data = {
        numbers,
      }
      const response = await SimService.getOrderDataByPhoneNumbrt(data)
      console.log('fetchOrdersByPhoneNumber response:', response)
      return response.data
    } catch (error) {
      setError(true)
      console.log(error)
    }
  }

  const handleSearch = async () => {
    console.log('phoneNumber:', phoneNumberInputRef.current?.value)
    const phoneNumber = phoneNumberInputRef.current?.value as string
    if (phoneNumber != '') {
      const ordersByPhoneNumber = await fetchOrdersByPhoneNumber(phoneNumber)
      console.log('ordersByPhoneNumber:', ordersByPhoneNumber)
      setSearchedOrders(ordersByPhoneNumber)
      setTotalOrders(ordersByPhoneNumber.length)
      setPagesCount(1)
    } else {
      await fetchOrderData(startDate, endDate, currentPage, providerId as unknown as number)
    }
  }

  const handleOrderStateColor = (state: string) => {
    console.log('state', state)
    if (state == 'Waiting Activation') return 'even-larger-badge badge badge-light-primary'
    else if (state == 'Activated') return 'even-larger-badge badge badge-light-success'
    else if (state == 'Paid') return 'even-larger-badge badge badge-light-warning'
    else if (state == 'Indelivery') return 'even-larger-badge badge badge-light-info'
    else return 'even-larger-badge badge badge-light'
  }

  const convertTimeTo12HourFormat = (timeString: any) => {
    // Splitting the time string into its components
    const timeComponents = timeString.split(':')

    // Extracting the hour, minute, and second
    const hour = parseInt(timeComponents[0])
    const minute = timeComponents[1]
    const second = timeComponents[2].split('.')[0] // Removing the milliseconds

    // Adjusting the hour to 12-hour format and determining AM or PM
    let adjustedHour = hour % 12
    const amPm = hour < 12 ? 'AM' : 'PM'

    // Handling special cases when the adjusted hour is 0
    if (adjustedHour === 0) {
      adjustedHour = 12 // 12 AM
    }

    // Creating the formatted time string
    const formattedTime = `${adjustedHour}:${minute}:${second} ${amPm}`

    return formattedTime
  }

  // console.log('startDate:', startDate)
  // console.log('endDate:', endDate)
  const handleExport = async () => {
    const response = await SimService.GetOrderData({
      FromDate: startDate,
      ToDate: endDate,
      pageNumber: 1,
      ProviderIds: providerId as unknown as number,
      PageSize: 100,
      OrderStatus: orderStatus,
      UserId: userId,
    })
    const totalPages = response.data.totalPages

    let financeDataAllPages:any[] = []

    for (let i = 1; i <= totalPages; i++) {
      const response = await SimService.GetOrderData({
        FromDate: startDate,
        ToDate: endDate,
        pageNumber: i,
        ProviderIds: providerId as unknown as number,
        PageSize: 100,
        OrderStatus: orderStatus,
        UserId: userId,
      })

      // const orders = await getOrdersFromPayments(response.data.data as any)

      // const financeData = createFinanceData(response?.data.data, orders)

      financeDataAllPages.push(...response.data.data)
    }

    let newData: any[] = []

    financeDataAllPages.forEach((data) => {
      data.orders.forEach((order: any) => {
        newData.push([
          order.id,
          order.orderId,
          order.orderState.nameEn,
          order.createdAt,
          order.fullName,
          order.idType,
          order.selectedSimType === 1 ? 'SIM' : order.selectedSimType === 2 ? 'eSIM' : '',
          order.phoneNumber,
          order.planName,
          order.orderTotalAmount,
        ])
      })
    }
      
      )


    const header = [
      // id	paymentId	paymentFor	name	email	amount	entityType	createdDate	requestDate	expectedDate	amountCredited	hyperPayExpense	paymentNetProfit	paymentStatus	orders	productId	productName	productCost	productSRP	productSold	productNETProfit
      'ID',
      'Order ID',
      'Order Status',
      'Order Date',
      'Customer Name',
      'Id Type',
      'SIM Type',
      'Mobile Number',
      'Product Name',
      'Total Amount'
    ]

    const wb = XLSX.utils.book_new()

    const ws = XLSX.utils.sheet_add_aoa(XLSX.utils.json_to_sheet(newData), [header])
    wb.SheetNames.push('Finance Sheet')
    wb.Sheets['Finance Sheet'] = ws

    XLSX.writeFile(wb, 'Finance Sheet.xlsx')
  }
  return (
    <div className='card'>
      {/* HEADER START */}
      <div className='card-header border-0 pt-5'>
        <div className='row align-items-center w-100'>
          <div className='col'>
            <img
              src={imagePathSmall as string}
              className='w-50px h-50px rounded-circle'
              alt='provider'
            />
          </div>

          <div className='col-4'>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              onStartDateChange={(value) => {
                if (value)
                  setStartDate(
                    new Date(
                      value?.getFullYear() as number,
                      value?.getMonth() as number,
                      value?.getDate() as number,
                      0,
                      0,
                      0,
                      0
                    )
                  )
              }}
              onEndDateChange={(value) => {
                if (value)
                  setEndDate(
                    new Date(
                      value?.getFullYear() as number,
                      value?.getMonth() as number,
                      value?.getDate() as number,
                      23,
                      59,
                      59,
                      999
                    )
                  )
              }}
              maximumDate={new Date(new Date().setHours(23, 59, 59, 999))}
              minimumLength={1}
              format='dd MMM yyyy'
              locale={enGB}
            >
              {({startDateInputProps, endDateInputProps, focus}) => (
                <div className='date-range d-flex'>
                  <input
                    className={
                      'form-control form-control-lg form-control-solid input' +
                      (focus === 'startDate' ? ' -focused' : '')
                    }
                    {...startDateInputProps}
                    placeholder='Start date'
                    style={{borderRadius: '0.5rem 0 0 0.5rem'}}
                  />
                  <span className='date-range_arrow' />
                  <input
                    className={
                      'form-control form-control-lg form-control-solid input' +
                      (focus === 'endDate' ? ' -focused' : '')
                    }
                    {...endDateInputProps}
                    placeholder='End date'
                    style={{borderRadius: '0 0.5rem 0.5rem 0'}}
                  />
                </div>
              )}
            </DateRangePicker>
          </div>

          <div className='col-3 d-flex align-items-center position-relative'>
            <KTSVG
              path='/media/icons/duotune/general/gen021.svg'
              className='svg-icon-1 position-absolute ms-6'
            />
            <input
              type='text'
              data-kt-user-table-filter='search'
              className='form-control w-370px form-control-solid ps-14'
              placeholder='Search Order'
              ref={phoneNumberInputRef}
            />
            {/* button */}
            <button
              type='button'
              className='btn btn-sm btn-primary btn-active-light-primary position-absolute top-50 end-0 translate-middle-y me-1'
              onClick={handleSearch}
            >
              Search
            </button>
          </div>

          <div className='col-2'>
            <select
              className='form-select form-select-solid form-select-lg fw-bold px-4'
              onChange={(e) => setOrderStatus(e.target.value as unknown as number)}
            >
              <option value=''>All</option>
              <option value='3'>Payment Pending</option>
              <option value='5'>Waiting Activation</option>
              <option value='7'>Indelivery</option>
              <option value='6'>Paid</option>
              {/* <option value='1'>Failed</option> */}
              <option value='8'>Activated</option>
            </select>
          </div>
          
          <div className='col d-flex'>
            <div className='border border-gray-300 border-dashed rounded min-w-125px px-4  mb-5'>
              <div className='d-flex align-items-center'>
                <KTSVG
                  path='/media/icons/duotune/communication/com014.svg'
                  className='svg-icon-3 svg-icon-success me-2'
                />
                <div className='fs-2 fw-bolder'>{totalOrders}</div>
              </div>
              <div className='fw-bold fs-6 text-gray-400'>Total Orders</div>
            </div>
          </div>
          <div className='col d-flex'>
            <button
              type='button'
              className='btn btn-sm btn-primary btn-active-light-primary'
              onClick={handleExport}
            >
              Export
            </button>
          </div>
        </div>
      </div>
      {/* HEADER END */}

      {/* BODY START */}
      {error ? (
        <div className='card'>
          <div className='card-body'>
            <h5 className='card-title'>Error</h5>
            <p className='card-text'>Something went wrong</p>
          </div>
        </div>
      ) : searchedOrders.length <= 0 ? (
        <div style={{height: '100%'}} className='card'>
          <div className='card-body'>
            <h5 className='card-title text-center card-text'>No matching orders were found</h5>
          </div>
        </div>
      ) : (
        <table
          id='kt_table_users'
          className='table table-hover align-middle table-row-dashed fs-6 gy-5 dataTable'
        >
          <thead>
            <tr className='text-center text-muted fw-bolder fs-6 text-uppercase gs-0'>
              <th>Order id</th>
              <th>Order Status</th>
              <th>Order Date</th>
              <th>Customer Name</th>
              <th>Id Type</th>
              <th>SIM Type</th>
              <th>Mobile Number</th>
              <th>Product Name</th>
              <th>Total Amount</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className='text-gray-600 fw-bold'>
            {searchedOrders.map((order, index) => {
              return (
                <tr key={index} className='text-muted text-gray-600 fs-5 gs-0'>
                  <td className={'text-center min-w-100px mw-200px'}>{order.id}</td>
                  <td className='text-center'>
                    <span
                      className={`${handleOrderStateColor(
                        order.orderState && order.orderState.nameEn
                      )}`}
                    >
                      {order.orderState && order.orderState.nameEn}
                    </span>
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>
                    {order.createdAt.split('T')[0]}{' '}
                    {convertTimeTo12HourFormat(order.createdAt.split('T')[1])}
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.fullName}</td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.idType}</td>
                  <td className={`text-center min-w-100px mw-200px text-lg`}>
                    {order.selectedSimType === 1 ? (
                      <span className='even-larger-badge badge badge-light-warning text-lg'>
                        SIM
                      </span>
                    ) : order.selectedSimType === 2 ? (
                      <span className='even-larger-badge badge badge-light-info'>eSIM</span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.phoneNumber}</td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.planName}</td>
                  <td className={'text-center min-w-100px mw-200px'}>{order.orderTotalAmount}</td>
                  <td className='text-center'>
                    <a href={`/accounts/${order.userId}`} className='btn btn-sm btn-light'>
                      View User
                    </a>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {/* BODY END */}

      {/* FOOTER START */}
      {pagesCount > 1 && (
        <div className='col-12 mt-10 card p-3'>
          <div className='row'>
            <div className='col-12'>
              <div className='text-center'>
                <nav aria-label='Page navigation example'>
                  <ul className='pagination'>
                    <li className='page-item'>
                      <a className='page-link' href='#' aria-label='Previous'>
                        <span aria-hidden='true'>&laquo;</span>
                        <span className='sr-only'>Previous</span>
                      </a>
                    </li>
                    {Array(pagesCount)
                      .fill(0)
                      .map((item, index) => {
                        return (
                          <li
                            className={currentPage == index + 1 ? 'page-item active' : 'page-item'}
                            key={index}
                            onClick={() => {
                              setCurrentPage(index + 1)
                              fetchOrderData(
                                startDate,
                                endDate,
                                index + 1,
                                providerId as unknown as number,
                                orderStatus,
                                userId
                              )
                            }}
                          >
                            <a className='page-link'>{index + 1}</a>
                          </li>
                        )
                      })}
                    <li className='page-item'>
                      <a className='page-link' href='#' aria-label='Next'>
                        <span aria-hidden='true'>&raquo;</span>
                        <span className='sr-only'>Next</span>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* FOOTER END */}
    </div>
  )
}

export default SimOrders
