import {REACT_APP_API_SIM} from '../config'
// import PromoCode from '../models/PromoCode'
import axios from './interceptors/axios.global'
const api = `${REACT_APP_API_SIM}/v2`

// /api/v{version}/Statistics/GetOrderData
const GetOrderData = async (params: any) => {
  // console.log('params:', params)
  // console.log('orderStatus:', orderStatus)
  // console.log('providerId:', providerId)
  // if (providerId && orderStatus)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?ProviderIds=${providerId}&pageNumber=${pageNumber}&PageSize=${PageSize}&OrderStatus=${orderStatus}`
  //   )
  // else if (providerId)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}&ProviderIds=${providerId}`
  //   )
  // else if (orderStatus)
  //   return axios.get(
  //     `${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}&OrderStatus=${orderStatus}`
  //   )
  // else
  //   return axios.get(`${api}/Statistics/GetOrderData?pageNumber=${pageNumber}&PageSize=${PageSize}`)
  return axios.get(`${api}/Statistics/GetOrderData`, {params: params})
}

// /api/v{version}/Statistics/GetStatisticsNumber
const GetStatisticsNumber = async (params: any) => {
  return axios.get(`${api}/Statistics/GetStatisticsNumber`, {params: params})
}

const getOrderDataByPhoneNumbrt = async (data: {
  numbers: {phoneNumber: String; countryCode: String}[]
}) => {
  console.log('numbers:', data)
  return axios.post(`${api}/Statistics/getOrderDataByPhoneNumbrt`, data)
}

const SimService = {
  GetStatisticsNumber,
  GetOrderData,
  getOrderDataByPhoneNumbrt,
}
export default SimService
